<template>
    <div
        class="fixed mt-8 lg:mt-0 inset-0 z-20"
        v-if="isEditAdmin || isCreateAdmin"
    >
        <div
            class="absolute flex right-0 bg-white h-full shadow-lg transition-all w-full lg:w-3/7 desktop:w-1/4"
        >
            <div
                class="relative mx-8 my-4 desktop:my-8 w-full justify-between "
            >
                <div class="desktop:pb-4">
                    <h2
                        v-if="isEditAdmin"
                        class="max-w-6xl desktop:mt-16 text-lg desktop:text-2xl leading-6 text-left font-bold text-gray-900"
                    >
                        {{ $t("setting.edit_admin") }}
                    </h2>
                    <h2
                        v-if="isCreateAdmin"
                        class=" max-w-6xl desktop:mt-16 text-lg desktop:text-2xl leading-6 text-left font-bold text-gray-900"
                    >
                        {{ $t("setting.add_admin") }}
                    </h2>
                    <div class="mt-4 desktop:mt-10 px-2 desktop:px-4">
                        <label
                            for="last_name"
                            class="block ml-1 text-sm desktop:text-lg font-medium text-gray-500 text-left"
                            >{{ $t("users.l_name") }}
                            <span class="text-red-500">*</span></label
                        >
                        <input
                            name="last_name"
                            id="last_name"
                            ref="last_name"
                            class="mt-1 mb-2 desktop:mb-8 border w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 text-sm desktop:text-base"
                        />
                        <label
                            for="first_name"
                            class="block ml-1 text-sm desktop:text-lg font-medium text-gray-500 text-left"
                            >{{ $t("users.f_name") }}
                            <span class="text-red-500">*</span></label
                        >

                        <input
                            name="first_name"
                            id="first_name"
                            ref="first_name"
                            class="mt-1 mb-4 desktop:mb-10 border w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 text-sm desktop:text-base"
                        />
                        <label
                            for="email"
                            class="block ml-1 text-sm desktop:text-lg font-medium text-gray-500 text-left"
                            >{{ $t("users.email") }}
                            <span class="text-red-500">*</span>
                        </label>
                        <input
                            name="email"
                            id="email"
                            ref="email"
                            type="email"
                            :readonly="isEditAdmin"
                            class="mt-1 mb-4 desktop:mb-10 border w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 text-sm desktop:text-base"
                        />
                        <label
                            for="job_title"
                            class="block ml-1 text-sm desktop:text-lg font-medium text-gray-500 text-left"
                        >
                            {{ $t("setting.job_title") }}

                            <span class="text-red-500">*</span>
                        </label>
                        <input
                            name="job_title"
                            id="job_title"
                            ref="job_title"
                            class="mt-1 mb-4 desktop:mb-10 border w-full border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 text-sm desktop:text-base"
                        />
                        <label
                            for="admin_role"
                            class="block ml-1 text-sm desktop:text-lg font-medium text-gray-500 text-left"
                        >
                            {{ $t("setting.admin_type") }}

                            <span class="text-red-500">*</span>
                        </label>
                        <select
                            name="admin_role"
                            id="admin_role"
                            ref="admin_role"
                            class="mt-1 border py-2 w-full text-center rounded-md shadow-sm border-gray-300"
                            ><option selected value="0" disabled>{{
                                $t("setting.select_one")
                            }}</option
                            ><option :selected="selectedRole === 2" value="2">{{
                                $t("setting.super_admin")
                            }}</option>
                            <option :selected="selectedRole === 3" value="3">{{
                                $t("setting.admin")
                            }}</option></select
                        >
                    </div>
                </div>
                <div class="absolute bottom-0 right-0">
                    <div class="flex justify-between">
                        <div></div>
                        <div>
                            <button
                                @click="onDiscard"
                                class="bg-white border border-gray-400 rounded-md text-black py-1 px-4 desktop:py-2 desktop:px-8 mr-3"
                            >
                                {{ $t("button.discard") }}
                            </button>
                            <button
                                v-if="isEditAdmin"
                                @click="editAdmin"
                                :disabled="isSubmitted"
                                class="bg-black rounded-md text-white py-1 px-4 ml-2 desktop:py-2 desktop:px-8 transition duration-500 ease-in-out"
                                :class="{ 'opacity-30': isSubmitted }"
                            >
                                {{ $t("button.save") }}
                            </button>
                            <button
                                @click="inviteAdmin"
                                v-if="isCreateAdmin"
                                :disabled="isSubmitted"
                                class="bg-black rounded-md text-white py-1 px-4 ml-2 desktop:py-2 desktop:px-8 transition duration-500 ease-in-out"
                                :class="{ 'opacity-30': isSubmitted }"
                            >
                                {{ $t("button.invite") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AdminSidebar",
    data() {
        return {
            userID: -1,
            selectedRole: 0,
            isInitial: true,
            isSubmitted: false,
            selected: [],
            isDropDown: false
        }
    },
    props: {
        planList: Object,
        user: Object,
        isEditAdmin: Boolean,
        isCreateAdmin: Boolean
    },
    emits: ["onDiscard", "onSave"],
    updated() {
        if (this.isEditAdmin && this.isInitial) {
            this.$refs.last_name.value = this.user.surname
            this.$refs.first_name.value = this.user.given_name
            this.$refs.email.value = this.user.email
            this.$refs.job_title.value = this.user.job_title
            this.selectedRole = this.user.admin_role
            this.isInitial = false
        }
    },
    // updated() {
    //     if (this.isEditAdmin) {
    //         this.$refs.last_name.value = this.user.user_name.split(" ")[1]
    //         this.$refs.first_name.value = this.user.user_name.split(" ")[0]
    //         this.$refs.job_position.value = this.user.position
    //         this.$refs.job_department.value = this.user.unit
    //         this.$refs.email.value = this.user.email
    //         this.selected = this.user.plan_id
    //         this.isEditing = true
    //     }
    // },
    // beforeRouteLeave(to, from, next) {
    //     const answer = window.confirm(
    //         this.$i18n.locale === "en"
    //             ? `There are ${this.planUsers} users currently associated with this Spending Plan.`
    //             : "變更尚未儲存，你要離開此頁嗎？"
    //     )
    //     if (answer) {
    //         next()
    //     } else {
    //         next(false)
    //     }
    // },
    methods: {
        onInitial() {
            this.isInitial = true
        },
        onDiscard() {
            this.selectedRole = 0
            this.$emit("onDiscard")
        },
        changeCountryCode() {
            this.isDropDown = false
            this.$refs.phone.focus()
        },
        isNumber(e) {
            let char = e.key
            if (/^[0-9]+$/.test(char)) return true
            else e.preventDefault()
        },
        // onSave() {
        //     const answer = window.confirm(
        //         this.$i18n.locale === "en"
        //             ? `There are ${this.planCount} users currently associated with this Spending Plan.\nChanges will apply to the users immediately.`
        //             : `當前有${this.planCount}個用戶正在參與此支出計劃。\n更改將會立即套用至用戶。`
        //     )
        //     if (answer) {
        //         this.updatePlan()
        //     }
        // },
        // async submitEdit() {
        //     const token = await this.$auth.getTokenSilently()
        //     const data = {
        //         user_id: this.$auth.user.value.sub,
        //         profile_id: this.user.profile_id,
        //         surname: this.$refs.last_name.value,
        //         given_name: this.$refs.first_name.value,
        //         dial_code: this.$refs.country_code.value,
        //         phone: this.$refs.phone.value,
        //         position: this.$refs.job_position.value,
        //         unit: this.$refs.job_department.value
        //     }
        //     const url =
        //         process.env.VUE_APP_API_BASE_URL +
        //         `/user/profile/edit?lang=${this.$i18n.locale}`
        //     if (data.surname !== "" && data.given_name !== "") {
        //         this.$axios
        //             .put(url, data, {
        //                 headers: {
        //                     Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
        //                 }
        //             })
        //             .then(res => {
        //                 this.submitEditPlans()
        //             })
        //     } else alert("Empty input.")
        // },
        // async submitEditPlans() {
        //     const token = await this.$auth.getTokenSilently()
        //     const data = {
        //         user_id: this.$auth.user.value.sub,
        //         profile_id: this.user.profile_id,
        //         plan_list: this.selected
        //     }
        //     const url =
        //         process.env.VUE_APP_API_BASE_URL +
        //         `/user/plan/edit?lang=${this.$i18n.locale}`
        //     if (data.surname !== "" && data.given_name !== "") {
        //         this.$axios
        //             .put(url, data, {
        //                 headers: {
        //                     Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
        //                 }
        //             })
        //             .then(res => {
        //                 this.$emit("onSave")
        //             })
        //     } else alert("Empty input.")
        // },
        async inviteAdmin() {
            this.isSubmitted = true
            const token = await this.$auth.getTokenSilently()
            const data = {
                user_id: this.$auth.user.value.sub,
                surname: this.$refs.last_name.value,
                given_name: this.$refs.first_name.value,
                email: this.$refs.email.value,
                job_title: this.$refs.job_title.value,
                admin_role: parseInt(this.$refs.admin_role.value)
            }
            const url =
                process.env.VUE_APP_API_BASE_URL +
                `/admin/invite?lang=${this.$i18n.locale}`
            if (
                (data.surname !== "" &&
                    data.given_name !== "" &&
                    data.email !== "" &&
                    data.admin_role === 2) ||
                data.admin_role === 3
            ) {
                this.$axios
                    .post(url, data, {
                        headers: {
                            Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                        }
                    })
                    .then(res => {
                        this.$emit("onSave")
                    })
            } else alert("Empty input.")
            setTimeout(() => {
                this.isSubmitted = false
            }, 1200)
        },
        async editAdmin() {
            this.isSubmitted = true
            const token = await this.$auth.getTokenSilently()
            const data = {
                user_id: this.$auth.user.value.sub,
                target_user_id: this.user.user_id,
                surname: this.$refs.last_name.value,
                given_name: this.$refs.first_name.value,
                job_title: this.$refs.job_title.value,
                admin_role: parseInt(this.$refs.admin_role.value)
            }
            const url =
                process.env.VUE_APP_API_BASE_URL +
                `/admin/user/edit?lang=${this.$i18n.locale}`
            if (
                (data.surname !== "" &&
                    data.given_name !== "" &&
                    data.email !== "" &&
                    data.admin_role === 2) ||
                data.admin_role === 3
            ) {
                this.$axios
                    .put(url, data, {
                        headers: {
                            Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                        }
                    })
                    .then(res => {
                        this.$emit("onSave")
                    })
            } else alert("Empty input.")
            setTimeout(() => {
                this.isSubmitted = false
            }, 1200)
        }
    }
}
</script>

<style scoped></style>

<template>
    <div class="flex justify-center items-center">
        <button :disabled="disable" @click="onPress">
            <div
                class="w-10 h-6 my-1 flex items-center bg-gray-300 rounded-full p-1 duration-300 ease-in-out"
                :class="{ 'bg-green-400': toggle }"
            >
                <div
                    class="bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out"
                    :class="{ 'translate-x-4': toggle }"
                ></div>
            </div>
        </button>
    </div>
</template>

<script>
export default {
    name: "Switch",
    props: { toggle: { required: true, type: Boolean }, disable: Boolean },
    emits: ["onPress"],
    methods: {
        onPress() {
            this.$emit("onPress")
        }
    }
}
</script>

<style scoped></style>
